import React from 'react'
import {graphql} from "gatsby";
import PropTypes from "prop-types";

import Layout from '../components/Layout'
import PageHeading from '../components/PageHeading'
import ArticleCard from "../components/ArticleCard";

const SingleLevelCategoryPage = (props) => {
    const {data} = props;
    const {edges: posts} = data.allMarkdownRemark;

    return (
        <Layout title={props.pageContext.label}
                description={props.pageContext.description}>
            <PageHeading heading={props.pageContext.label}
                         className="title is-size-3 is-size-4-touch is-size-4-mobile"/>
            <section className="section">
                <div className="container">
                    <div className="content">
                        <div className={`columns is-multiline ${props.pageContext.category}-roll-blog`}>
                            {posts && posts.map(({node: post}) => <ArticleCard post={post} key={post.id}/>)}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

SingleLevelCategoryPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array
        })
    })
}

export default SingleLevelCategoryPage;

export const bookcaseArticlesPageQuery = graphql`
    query SingleLevelCategoryQuery($category: String!) {
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "blog-post" }, categories: { in: [$category] } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    excerpt(pruneLength: 200, truncate: true)
                    frontmatter {
                        slug
                        title

                        date(formatString: "MMMM DD, YYYY")
                        featuredpost
                        featuredimage {
                            childImageSharp {
                                fluid(maxWidth: 340, maxHeight: 340, fit: COVER) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
